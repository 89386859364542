import React, { useEffect } from "react";
import { Divider, Grid, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../../../theme";
import UploadData from "../../projects/Components/UploadData";
import LocationsPins from "../../projects/Components/LocationsPins";
import MultiOrderMissions from "./MultiOrderMissions";
import { multiLocaitonMissionCol } from "../../../../lib/constants";
import { orderBox } from "../../../../lib/styleConstants";
import TextStructures from "../../../global/elements/TypographyElements/TextStructures";
import InfoBox from "../../../global/elements/InfoBox";

const styles = {
  dividerCls: {
    borderColor: theme.palette.grey.dividersColor,
    width: "inherit",
  },
  headingCls: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  missionOrderCls: { border: "1px solid black", padding: "20px" },
  mTop10: {
    marginTop: "20px",
  },
  errorMsg: {
    color: theme.palette.status.errorDark,
  },
};

const SpecifyLocationMultiiOrder = ({
  projectRef,
  setProjectRef,
  uploadValidData,
  setUploadValidData,
  // setDocId,
  setFieldValue,
  values,
  errorDisplay,
  isGuestUser,
}) => {
  const setData = ({ missionData, projectReference }) => {
    setUploadValidData(
      missionData.data.createdFileData.allMissionCoordinatesInfo
    );
    setFieldValue(
      "allCreatedValidMissions",
      missionData.data.createdFileData.allMissionCoordinatesInfo
    );
    const location =
      missionData.data.createdFileData.allMissionCoordinatesInfo[0].location;
    setFieldValue("location", location);

    // setDocId(missionData.data.createdFileData.id);
    setFieldValue("docIdUploadedFiles", missionData.data.createdFileData.id);
    if (projectReference) {
      setProjectRef(projectReference);
      setFieldValue("projectRef", projectReference);
    }
  };

  useEffect(() => {
    setFieldValue("allCreatedValidMissions", uploadValidData);
  }, [uploadValidData]);

  return (
    <Box sx={orderBox}>
      <TextStructures
        text="Upload your KLM or CSV file"
        number={4}
        marginBottom
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={styles.headingCls}>
            Please follow the instructions below for your CVS and KML files
          </Typography>
          <Divider sx={styles.dividerCls}></Divider>
        </Grid>
        <Grid item xs={12}>
          <UploadData
            setData={setData}
            projectId={projectRef?.id}
            showInfo={false}
            projectName={values.missionName}
            showGlobalLoad={isGuestUser}
          />
        </Grid>
        {uploadValidData?.length > 0 && (
          <>
            <Grid item xs={12} md={7}>
              <LocationsPins
                uploadValidData={uploadValidData}
                hint={""}
                mapHeight={"502px"}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InfoBox
                title={"Mission order information"}
                subText={
                  "For area projects, a minimum order of 100 hectares is required. Any orders below this minimum requirement will be charged at the rate of 100 hectares"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MultiOrderMissions
                uploadValidData={uploadValidData}
                setUploadValidData={setUploadValidData}
                columns={multiLocaitonMissionCol}
              />
            </Grid>
          </>
        )}
        {errorDisplay && (
          <Typography variant="body1" sx={styles.errorMsg}>
            Please upload a kml file or a csv file
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

SpecifyLocationMultiiOrder.propTypes = {
  projectRef: PropTypes.object,
  setProjectRef: PropTypes.func.isRequired,
  uploadValidData: PropTypes.array,
  setUploadValidData: PropTypes.func.isRequired,
  // setDocId: PropTypes.func.isRequired,
  setFieldValue: PropTypes.object.isRequired,
  values: PropTypes.object,
  errorDisplay: PropTypes.bool,
  isGuestUser: PropTypes.bool,
};

SpecifyLocationMultiiOrder.defaultProps = {
  projectRef: undefined,
  uploadValidData: [],
  values: {},
  errorDisplay: false,
  isGuestUser: false,
};

export default SpecifyLocationMultiiOrder;
